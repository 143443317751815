<template>
  <v-container :style="{ padding: '20px 5%' }" class="view-currency full-width">
    <v-dialog v-model="accountVerificationDialog" persistent max-width="600px">
      <v-card class="white-card">
        <v-card-title class="text-h5">Account Verification in Progress</v-card-title>
        <v-card-text>
          Your account verification is currently in progress. <br></br> This process
          ensures compliance with regulatory standards and protects your
          account. If you have any questions or need assistance, please contact
          our support team at 066 560 1607. <br></br> Thank you for your patience.
        </v-card-text>
        <v-card-actions>
          <v-btn color="pink" dense rounded dark @click="routeToAccount">Account</v-btn>
          <v-btn color="primary" dense rounded dark @click="verifyAccount">Verify</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" v-if="loading" class="loader-container">
      <v-col cols="12" class="text-center">
        <v-icon class="mdi mdi-bitcoin rotating-loader" color="primary" size="64"></v-icon>
        <h3>LOADING...</h3>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !showSuccess" justify="center">
      <img v-if="baseCurrencyDetails && baseCurrencyDetails.icon_url" class="mx-2" :style="{ height: '80px' }"
        :src="baseCurrencyDetails.icon_url" />
      <v-icon large class="black-text">mdi-swap-horizontal</v-icon>
      <img v-if="quoteCurrencyDetails && quoteCurrencyDetails.icon_url" class="mx-2" :style="{ height: '80px' }"
        :src="quoteCurrencyDetails.icon_url" />
    </v-row>
    <v-row v-if="!loading && !showSuccess" justify="center">
      <h3 class="my-4">Request For Quote (RfQ)</h3>
    </v-row>
    <v-row v-if="!loading && !requested && !showSuccess" justify="center">
      <v-col cols="12" md="6" class="py-0">
        <v-row justify="center">
          <h5>Select Market</h5>
        </v-row>
        <v-row justify="center">
          <v-row justify="center" class="my-2">
            <v-btn v-for="currency in quoteCurrencies" :key="currency" :class="{
              'primary--text': selectedQuoteCurrency === currency,
              'grey--text': selectedQuoteCurrency !== currency,
            }" text small @click="selectCurrency(currency)">
              {{ currency.toUpperCase() }}
            </v-btn>
          </v-row>
          <v-select outlined solo dense rounded :hide-details="true" class="full-width" v-model="selectedMarketName"
            :items="filteredMarkets" item-text="name" item-value="name" @change="updateSelectedMarket">
            <template v-slot:selection="data">
              <v-row align="center" justify="end">
                <v-avatar v-if="baseCurrencyDetails && baseCurrencyDetails.icon_url" size="24" class="mr-3">
                  <img :src="baseCurrencyDetails.icon_url" :alt="baseCurrencyDetails.name" />
                </v-avatar>
                <span>{{ data.item.name }}</span>
                <v-avatar v-if="quoteCurrencyDetails && quoteCurrencyDetails.icon_url" size="24" class="ml-3">
                  <img :src="quoteCurrencyDetails.icon_url" :alt="quoteCurrencyDetails.name" />
                </v-avatar>
              </v-row>
            </template>
            <template v-slot:item="data">
              <v-row align="center" justify="center">
                <v-avatar v-if="data.item.base_currency_icon" size="24" class="mr-3">
                  <img :src="data.item.base_currency_icon" :alt="data.item.base_currency" />
                </v-avatar>
                <span>{{ data.item.name }}</span>
                <v-avatar v-if="data.item.quote_currency_icon" size="24" class="ml-3">
                  <img :src="data.item.quote_currency_icon" :alt="data.item.quote_currency" />
                </v-avatar>
              </v-row>
            </template>
          </v-select>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !requested && !showSuccess" class="mt-6" justify="center" align="end">
      <v-card :style="{ 'min-height': '350px' }" class="exchange-card mt-4">
        <v-tabs light v-model="currentSelection" background-color="transparent" color="basil" grow>
          <v-tab v-for="(item, index) in items" :key="index">{{ item }}</v-tab>
        </v-tabs>
        <v-tabs-items accent="red" v-model="currentSelection">
          <v-tab-item>
            <v-card color="basil" class="pa-6 pb-8">
              <v-row justify="center">
                <h4>
                  Buy
                  {{
                    baseCurrencyDetails
                      ? baseCurrencyDetails.name
                      : "Base Currency"
                  }}
                  with
                  {{
                    quoteCurrencyDetails
                      ? quoteCurrencyDetails.name
                      : "Quote Currency"
                  }}
                  <v-btn @click="fillMaxAmount('buy')" text color="primary">
                    BUY MAX:
                    {{
                      formatNumber(maxQuoteAmount)
                        ? formatNumber(maxQuoteAmount)
                        : "Loading..."
                    }}
                  </v-btn>
                </h4>
              </v-row>
              <v-row class="mt-8" justify="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field v-model="baseAmount" label="AMOUNT" :hide-details="true" outlined dense>
                    <template v-slot:prepend>
                      <img v-if="
                        baseCurrencyDetails && baseCurrencyDetails.icon_url
                      " :src="baseCurrencyDetails.icon_url" :alt="baseCurrencyDetails.name"
                        style="height: 20px; width: 20px; margin-right: 5px;" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3" class="pa-0">
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar v-if="
                        baseCurrencyDetails && baseCurrencyDetails.icon_url
                      " size="24" class="mr-2">
                        <img :src="baseCurrencyDetails.icon_url" :alt="baseCurrencyDetails.name" />
                      </v-avatar>
                      <span>{{ baseCurrencyDetails.name }}</span>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row class="mt-4" justify="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field v-model="quoteAmount" :value="formatNumber(quoteAmount)" label="AMOUNT"
                    :hide-details="true" outlined dense>
                    <template v-slot:prepend>
                      <img v-if="
                        quoteCurrencyDetails && quoteCurrencyDetails.icon_url
                      " :src="quoteCurrencyDetails.icon_url" :alt="quoteCurrencyDetails.name"
                        style="height: 20px; width: 20px; margin-right: 5px;" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3" class="pa-0">
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar v-if="
                        quoteCurrencyDetails && quoteCurrencyDetails.icon_url
                      " size="24" class="mr-2">
                        <img :src="quoteCurrencyDetails.icon_url" :alt="quoteCurrencyDetails.name" />
                      </v-avatar>
                      <span>{{ quoteCurrencyDetails.name }}</span>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn @click="handleQuoteRequest()" rounded class="dark-blue-btn full-width mt-4">
                  GET A QUOTE
                </v-btn>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" class="pa-6 pb-8">
              <v-row justify="center">
                <v-row justify="center">
                  <h4>
                    Sell
                    {{
                      baseCurrencyDetails
                        ? baseCurrencyDetails.name
                        : "Base Currency"
                    }}
                    for
                    {{
                      quoteCurrencyDetails
                        ? quoteCurrencyDetails.name
                        : "Quote Currency"
                    }}
                    <v-btn @click="fillMaxAmount('sell')" color="primary" text>
                      SELL MAX:
                      {{
                        formatNumber(maxBaseAmount)
                          ? formatNumber(maxBaseAmount)
                          : "Loading..."
                      }}
                    </v-btn>
                  </h4>
                </v-row>
              </v-row>
              <v-row class="mt-7" justify="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field v-model="quoteAmount" label="AMOUNT" :hide-details="true" outlined dense>
                    <template v-slot:prepend>
                      <img v-if="
                        baseCurrencyDetails && baseCurrencyDetails.icon_url
                      " :src="baseCurrencyDetails.icon_url" :alt="baseCurrencyDetails.name"
                        style="height: 20px; width: 20px; margin-right: 5px;" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3" class="pa-0">
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar v-if="
                        baseCurrencyDetails && baseCurrencyDetails.icon_url
                      " size="24" class="mr-2">
                        <img :src="baseCurrencyDetails.icon_url" :alt="baseCurrencyDetails.name" />
                      </v-avatar>
                      <span>{{ baseCurrencyDetails.name }}</span>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row class="mt-4" justify="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field v-model="baseAmount" :value="formatNumber(baseAmount)" label="AMOUNT"
                    :hide-details="true" outlined dense>
                    <template v-slot:prepend>
                      <img v-if="
                        quoteCurrencyDetails && quoteCurrencyDetails.icon_url
                      " :src="quoteCurrencyDetails.icon_url" :alt="quoteCurrencyDetails.name"
                        style="height: 20px; width: 20px; margin-right: 5px;" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3" class="pa-0">
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar v-if="
                        quoteCurrencyDetails && quoteCurrencyDetails.icon_url
                      " size="24" class="mr-2">
                        <img :src="quoteCurrencyDetails.icon_url" :alt="quoteCurrencyDetails.name" />
                      </v-avatar>
                      <span>{{ quoteCurrencyDetails.name }}</span>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn @click="handleQuoteRequest()" rounded class="dark-blue-btn full-width mt-4">
                  GET A QUOTE
                </v-btn>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <v-row v-if="!loading && requested && !showSuccess" justify="center" class="mt-6">
      <v-card :style="{ 'min-height': '350px' }" class="exchange-card pa-4 mt-4">
        <v-row :style="{ 'border-bottom': '5px solid #00155F' }" justify="center">
          CURRENT QUOTE
        </v-row>
        <v-row class="py-4" :style="{ 'border-bottom': '1px solid lightgrey' }" justify="center">
          <h3>
            {{ action }} {{ baseCurrencyDetails ? baseCurrencyDetails.name : 'Base Currency' }} with
            {{ quoteCurrencyDetails ? quoteCurrencyDetails.name : 'Quote Currency' }}
          </h3>
        </v-row>
        <v-row class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h5>PRICE PER COIN</h5>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
            
              <h5 v-if="quoteAmount"> {{ actionCurrencyLabel }}  {{ formatNumber(rate) }}</h5>
              <h5 v-else-if="baseAmount">{{ otherCurrencyLabel }} {{ formatNumber(rate) }}</h5>
            </v-row>
          </v-col>
        </v-row>
        <!-- For BUY Action -->
        <v-row v-if="action === 'BUY'" class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h5>{{ action }}</h5>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
              <h5 v-if="baseAmount">{{ actionCurrencyLabel }} {{ baseAmount }}</h5>
              <h5 v-else-if="quoteAmount">{{ otherCurrencyLabel }} {{ displayTotalCost }}</h5>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="action === 'BUY'" class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h5>TOTAL COST</h5>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
              <h5 v-if="baseAmount">{{ otherCurrencyLabel }} {{ displayTotalCost }}</h5>
              <h5 v-else-if="quoteAmount">{{ actionCurrencyLabel }} {{ quoteAmount }}</h5>
            </v-row>
          </v-col>
        </v-row>

        <!-- For SELL Action -->
        <v-row v-if="action === 'SELL'" class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h5>{{ action }}</h5>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
              <h5 v-if="quoteAmount">{{ actionCurrencyLabel }} {{ quoteAmount }}</h5>
              <h5 v-else-if="baseAmount">{{ otherCurrencyLabel }} {{ displayTotalCost }}</h5>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="action === 'SELL'" class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h5>TOTAL PROCEEDS</h5>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
              <h5 v-if="baseAmount">{{ actionCurrencyLabel }} {{ baseAmount }}</h5>
              <h5 v-else-if="quoteAmount">{{ otherCurrencyLabel }} {{ displayTotalCost }}</h5>
            </v-row>
          </v-col>
        </v-row>
        <v-row :style="{ 'border-top': '1px solid lightgrey' }" class="mt-4 py-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <h6 class="grey-text">QUOTE EXPIRES IN</h6>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="end">
              <v-col cols="1" class="pa-0">
                <h6>{{ countdown }}</h6>
              </v-col>
              <v-col cols="11" class="pa-0">
                <v-row class="full-height" align="center">
                  <v-progress-linear height="10" striped rounded :value="progress"></v-progress-linear>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-4" justify="center">
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="center">
              <v-btn rounded class="white-blue-btn my-2" @click="handleDecline">DECLINE</v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-row justify="center">
              <v-btn rounded class="dark-blue-btn full-width my-2"
                @click="countdown > 0 ? handleAccept() : handleQuoteRefresh()">
                {{ countdown > 0 ? "ACCEPT" : "RESET" }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <h6 class="grey-text mt-4">
            WHEN TIMER RUNS OUT ACCEPT AND DECLINE WILL DISAPPEAR AND REFRESH
            WILL APPEAR
          </h6>
        </v-row>
      </v-card>
    </v-row>

    
    <v-row v-if="!loading && showSuccess" justify="center" class="mt-6">
  <v-card :style="{ width: '400px' }" class="exchange-card pa-6 text-center">
    
  

    <v-row v-if="!loading && showSuccess" justify="center" class="mt-6">
  <v-card :style="{ width: '400px' }" class="exchange-card pa-6 text-center">
    
    <!-- Centered Logo Section -->
    <v-row justify="center" class="mb-4">
      <img :style="{ 'max-width': '50%' }" width="150px" src="../assets/images/Logo.png" />
    </v-row>

    <!-- Centered Success Message -->
    <h5 class="text-center font-weight-bold mb-2" style="font-size: 1.4em;">Transaction is successful</h5>

    <!-- Main Amount Display: Buy or Sell -->
    <h3 class="text-center mt-2 mb-4" style="font-size: 2em; font-weight: bold;">
      {{ actionCurrencyLabel }} 
      {{ action === 'BUY' ? (quoteAmount ? formatNumber(quoteAmount) : '0.00') : (quoteAmount ? formatNumber(quoteAmount) : '0.00') }}
    </h3>

    <!-- Pay With Section -->
    <v-row v-if="action === 'BUY'" class="mt-4" justify="center">
      <v-col cols="12">
        <h5 class="font-weight-bold">Pay With</h5>
        <h5>{{ baseCurrencyDetails.id.toUpperCase() }} {{ formatNumber(totalCostto || 0) }}</h5>
      </v-col>
    </v-row>

    <v-row v-if="action === 'SELL'" class="mt-4" justify="center">
      <v-col cols="12">
        <h5 class="font-weight-bold">Pay With</h5>
        <h5>{{ quoteCurrencyDetails.id.toUpperCase() }} {{ formatNumber(totalCostto || 0) }}</h5>
      </v-col>
    </v-row>

    <!-- Exchange Rate Section -->
    <v-row justify="center" class="mt-4">
      <v-col cols="12">
        <h5 class="font-weight-bold">Exchange Rate</h5>
        <h5>{{ action === 'BUY' ? (formatNumber(rate)) : formatNumber(rate) }} {{ quoteCurrencyDetails.id.toUpperCase() }} = 1 {{ baseCurrencyDetails.id.toUpperCase() }}</h5>
      </v-col>
    </v-row>

    <!-- Done Button -->
    <v-row justify="center">
      <v-btn class="dark-blue-btn mt-8 px-8 py-4" rounded @click="handleDoneClick" style="font-size: 1.2em; background-color: #00155F; color: white;">
        Done
      </v-btn>
    </v-row>
  </v-card>
</v-row>


  </v-card>
</v-row>



    <v-row v-if="countdown <= 0" justify="center" class="mt-6"> </v-row>
  </v-container>
</template>

<script>
import emailjs from "@emailjs/browser";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import "vue-toastification/dist/index.css";

export default {
  data() {
    return {
      pageType: "Buy",
      items: ["Buy", "Sell"],
      snackbar: false,
      acceptButtonDisabled: false,
      accountVerificationDialog: false,
      userAccountStatus: "",
      tab: null,
      requested: false,
      countdown: 30,
      progress: 100,
      showSuccess: false,
      markets: [],
      selectedQuoteCurrency: "zar",
      quoteCurrencies: ["zar", "usdt", "eth", "btc"],
      totalCostto: null,
      totalCostfrom: null,
      currentSelection: 0,
      selectedMarketName: "BTC/ZAR",
      selectedMarket: null,
      baseCurrencyDetails: null,
      quoteCurrencyDetails: null,
      quoteToken: null,
      clientSN: null,
      rate: null,
      totalCost: null,
      baseAmount: "",
      quoteAmount: "",
      email: "",
      maxBaseAmount: null,
      maxQuoteAmount: null,
      loading: true,
      countdownIntervalId: null,
      quoteRequestIntervalId: null,
      quoteRequestCounter: 0,
    };
  },

  async mounted() {
    try {
      await this.fetchMarkets();
      await this.checkUserAccountStatus();
      await this.fetchClientEmailFromFirebase();
      await this.fetchQuoteCurrencies();
      await this.fetchAndFillMaxAmount();
      await this.fetchCurrencyIcons();
      this.selectCurrency(this.selectedQuoteCurrency);

      const storedCurrency = localStorage.getItem("selectedCoin");
      if (storedCurrency) {
        const parsedCurrency = JSON.parse(storedCurrency);
        this.selectedMarketName = `${parsedCurrency.currency}/ZAR`;
        await this.updateSelectedMarket(this.selectedMarketName);
      }

      this.loading = false;
      localStorage.removeItem("selectedCoin");
    } catch (error) {
      console.error("Error during mounted lifecycle:", error);
      this.accountVerificationDialog = false;
      this.loading = false;
    }
  },

  computed: {
    baseCurrencyLabel() {
      return this.baseCurrencyDetails && this.baseCurrencyDetails.name
        ? `${this.baseCurrencyDetails.id}`.toUpperCase()
        : "BASE CURRENCY";
    },
    quoteCurrencyLabel() {
      return this.quoteCurrencyDetails && this.quoteCurrencyDetails.name
        ? `${this.quoteCurrencyDetails.id}`.toUpperCase()
        : "QUOTE CURRENCY";
    },
    action() {
      return this.currentSelection === 0 ? 'BUY' : 'SELL';
    },
    actionCurrencyLabel() {
      let label;
      if (this.currentSelection === 0) {
        // Buy action
        label = this.baseAmount ? (this.baseCurrencyDetails ? this.baseCurrencyDetails.id : '') : (this.quoteCurrencyDetails ? this.quoteCurrencyDetails.id : '');
      } else {
        // Sell action
        label = this.baseAmount ? (this.quoteCurrencyDetails ? this.quoteCurrencyDetails.id : '') : (this.baseCurrencyDetails ? this.baseCurrencyDetails.id : '');
      }
      return label ? (label.toUpperCase() === 'ZAR' ? 'R' : label.toUpperCase()) : '';
    },
    actionAmount() {
      return this.baseAmount || this.quoteAmount;
    },
    filteredMarkets() {
      return this.markets.filter(
        (market) => market.quote_currency === this.selectedQuoteCurrency
      );
    },
    displayTotalCost() {
      if (this.totalCostto !== null) {
        return this.totalCostto;
      } else if (this.totalCostfrom !== null) {
        return this.totalCostfrom;
      }
      return null; // or any default value if needed
    },
    otherCurrencyLabel() {
      if (this.currentSelection === 0) {
        // If the base amount is filled in (focus on base currency)
        if (this.baseAmount) {
          return this.quoteCurrencyDetails ? this.quoteCurrencyDetails.id.toUpperCase() : '';
        }
        // If the quote amount is filled in (focus on quote currency)
        else if (this.quoteAmount) {
          return this.baseCurrencyDetails ? this.baseCurrencyDetails.id.toUpperCase() : '';
        }
      } else if (this.currentSelection === 1) {
        // If the base amount is filled in (focus on base currency)
        if (this.baseAmount) {
          return this.baseCurrencyDetails ? this.baseCurrencyDetails.id.toUpperCase() : '';

        }
        // If the quote amount is filled in (focus on quote currency)
        else if (this.quoteAmount) {
          return this.quoteCurrencyDetails ? this.quoteCurrencyDetails.id.toUpperCase() : '';
        }
      }
      return ''; // Default case, if no amounts are filled in
    }
  },

  methods: {
    formatNumber(value) {
      if (value == null) {
        return "";
      }
      return parseFloat(value)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    resetState() {
    
      this.requested = false;
      this.fetchAndFillMaxAmount();
      this.baseAmount = "";
      this.quoteAmount = "";
      this.totalCostfrom = null;
      this.totalCostto = null;
      this.rate = null;
      this.progress = 100;
      this.countdown = 30;
    },
    async updateSelectedMarket(selectedMarketName) {
      this.selectedMarket = this.markets.find(
        (market) => market.name === selectedMarketName
      );
      if (this.selectedMarket) {
        await this.fetchAndFilterCurrencies();
        await this.fetchAndFillMaxAmount();
      }
    },
    async fetchMarkets() {
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/OvexMarkets"
        );
        const data = await response.json();
        // Filter out any markets where the name includes "EUR"
        this.markets = data
          .filter((item) => !item.name.includes("EUR"))
          .map((item) => ({
            name: item.name,
            base_currency: item.base_currency,
            quote_currency: item.quote_currency,
          }));
      } catch (error) {
        this.$toast.error("Error fetching markets.");
        throw error;
      }
    },
    routeToAccount() {
      this.$router.push("/account").catch((err) => { });
      console.log("Routing to the account page...");
    },
    handleDoneClick() {
    // Reset the state or navigate to another page if needed
    this.resetState();
    this.showSuccess = false; // This will hide the success card
    this.sendEmailNotification();
  },
    verifyAccount() {
      this.$router
        .push("/options?section=IdentityVerification")
        .catch((err) => { });
      console.log("Routing to the account verification page...");
    },
    fetchQuoteCurrencies() {
      this.quoteCurrencies = ["zar", "usdt", "eth", "btc"];
    },
    updateFilteredMarkets(value) {
      this.selectedQuoteCurrency = value;
      this.fetchAndFilterCurrencies();
      this.fetchAndFillMaxAmount();
    },
    async selectCurrency(currency) {
      this.selectedQuoteCurrency = currency;
      this.updateFilteredMarkets(currency);
      if (this.filteredMarkets.length > 0) {
        this.selectedMarketName = this.filteredMarkets[0].name;
        await this.updateSelectedMarket(this.selectedMarketName);
      }
    },
    async checkUserAccountStatus() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "trusts", "companies"];
        for (let i = 0; i < collections.length; i++) {
          const collection = collections[i];
          const userRef = doc(db, collection, user.uid);
          try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists() && docSnap.data().state === "Approved") {
              return;
            } else if (docSnap.exists()) {
              break;
            }
          } catch (error) {
            this.$toast.error("Error checking user account status.");
            throw error;
          }
        }
        this.accountVerificationDialog = true;
        console.log("User account not approved. Showing verification dialog.");
      } else {
        console.log("No user logged in");
      }
    },
    sendEmailNotification() {
      const templateParams = {
        to_email: this.email,
        message: `You have successfully Bought ${this.baseCurrencyDetails.name} with ${this.quoteCurrencyDetails.name}.`,
      };

      emailjs
        .send(
          "service_9esjl09",
          "template_m23zyxh",
          templateParams,
          "mr1E1BaGMKZMEzWVu"
        )
        .then((response) => {
          console.log("Email sent successfully.", response);
        })
        .catch((error) => {
          console.error("Failed to send email.", error);
        });
    },
    async fetchAndFilterCurrencies() {
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/OvexCurrencies"
        );
        const allCurrencies = await response.json();
        const baseCurrencyName = this.selectedMarket.base_currency;
        const quoteCurrencyName = this.selectedMarket.quote_currency;

        const baseCurrency = allCurrencies.find(
          (currency) => currency.id === baseCurrencyName
        );

        const quoteCurrency = allCurrencies.find(
          (currency) => currency.id === quoteCurrencyName
        );

        this.baseCurrencyDetails = baseCurrency;
        this.quoteCurrencyDetails = quoteCurrency;
      } catch (error) {
        this.$toast.error("Error fetching and filtering currencies.");
        throw error;
      }
    },
    async fetchCurrencyIcons() {
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/OvexCurrencies"
        );
        const currencies = await response.json();

        console.log("Fetched Currencies:", currencies);

        if (this.baseCurrencyDetails) {
          const baseCurrency = currencies.find(
            (currency) => currency.id === this.baseCurrencyDetails.id
          );
          this.$set(
            this.baseCurrencyDetails,
            "icon_url",
            baseCurrency ? baseCurrency.icon_url : null
          );
        }

        if (this.quoteCurrencyDetails) {
          const quoteCurrency = currencies.find(
            (currency) => currency.id === this.quoteCurrencyDetails.id
          );
          this.$set(
            this.quoteCurrencyDetails,
            "icon_url",
            quoteCurrency ? quoteCurrency.icon_url : null
          );
        }

        this.markets = this.markets.map((market) => {
          const baseCurrency = currencies.find(
            (currency) => currency.id === market.base_currency
          );
          const quoteCurrency = currencies.find(
            (currency) => currency.id === market.quote_currency
          );

          return {
            ...market,
            base_currency_icon: baseCurrency ? baseCurrency.icon_url : null,
            quote_currency_icon: quoteCurrency
              ? quoteCurrency.icon_url
              : null,
          };
        });

        console.log(
          "Updated baseCurrencyDetails and quoteCurrencyDetails with Icons:",
          {
            baseCurrencyDetails: this.baseCurrencyDetails,
            quoteCurrencyDetails: this.quoteCurrencyDetails,
          }
        );
      } catch (error) {
        this.$toast.error("Error fetching currency icons.");
        throw error;
      }
    },
    sendEmail() {
      const action = this.currentSelection === 0 ? "bought" : "sold";
      const currency = this.baseCurrencyDetails
        ? this.baseCurrencyDetails.name
        : "currency";
      const amount = this.baseAmount || this.quoteAmount;

      this.sendEmailNotification(action, currency, amount);
    },
    findUserInCollections(uid, collections, index = 0) {
      if (index >= collections.length) {
        console.log("User not found in any collection");
        return;
      }

      const db = getFirestore();
      const userRef = doc(db, collections[index], uid);

      getDoc(userRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const ovexEmail = docSnap.data().OvexEmail;
            this.fetchAndFilterClientsByEmail(ovexEmail);
          } else {
            this.findUserInCollections(uid, collections, index + 1);
          }
        })
        .catch((error) => {
          console.error("Error fetching user document:", error);
          this.$toast.error("Error fetching user document.");
        });
    },
    startCountdown() {
      this.countdown = 30;
      this.progress = 100;
      this.countdownIntervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          this.progress = (this.countdown / 30) * 100;
        } else {
          clearInterval(this.countdownIntervalId);
          clearInterval(this.quoteRequestIntervalId); // Stop API calls
          this.acceptButtonDisabled = true; // Disable the accept button
        }
      }, 1000);
    },
    resetCountdown() {
      if (this.countdownIntervalId) {
        clearInterval(this.countdownIntervalId);
      }
      this.countdown = 30;
      this.progress = 100;
    },
    async fetchClientEmailFromFirebase() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const collections = ["individuals", "companies", "trusts"];
        for (let collection of collections) {
          const userRef = doc(db, collection, user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.clientSN) {
              this.clientSN = userData.clientSN;
              this.email = userData.email;
              console.log("Client SN:", this.clientSN);
              console.log("Client Email:", this.email);
            } else {
              console.log("clientSN not found in the document.");
            }
          } else {
            console.log(`No document found in ${collection} collection.`);
          }
        }
      } else {
        console.log("No user logged in");
      }
    },
    async fetchAndFillMaxAmount() {
      if (!this.clientSN || !this.selectedQuoteCurrency) {
        console.error(
          "Missing client SN or quote currency for max amount request"
        );
        return;
      }

      try {
        const response = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetAccounts`
        );
        const data = await response.json();
        const baseCurrencyAccount = data.find(
          (account) =>
            account.sn === this.clientSN &&
            account.currency === this.selectedMarket.base_currency
        );
        const quoteCurrencyAccount = data.find(
          (account) =>
            account.sn === this.clientSN &&
            account.currency === this.selectedQuoteCurrency
        );

        if (!baseCurrencyAccount || !quoteCurrencyAccount) {
          this.$toast.error(
            "The specified currency not associated with this user account. Please, reach out to support."
          );
          return;
        }

        this.maxBaseAmount = baseCurrencyAccount.balance;
        this.maxQuoteAmount = quoteCurrencyAccount.balance;
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    },
    fillMaxAmount(action) {
      if (
        !this.selectedMarket ||
        !this.baseCurrencyDetails ||
        !this.quoteCurrencyDetails
      ) {
        this.$toast.error("Please select a market and currencies first.");
        return;
      }

      if (action === "buy") {
        if (this.selectedQuoteCurrency === "zar") {
          this.quoteAmount = Math.floor(this.maxQuoteAmount);
        } else {
          this.quoteAmount = this.maxQuoteAmount;
        }
        this.baseAmount = "";
      } else if (action === "sell") {
        this.baseAmount = "";
        this.quoteAmount = this.maxBaseAmount;
      }
    },
    handleQuoteRequest() {
      if (this.baseAmount) {
        this.getQuoteBase();
      } else if (this.quoteAmount) {
        this.getQuoteFrom();
      } else {
        console.error("Please enter an amount to get a quote.");
      }

      if (this.baseAmount || this.quoteAmount) {
        this.requested = true;
      }
    },
    async getQuoteBase() {
      if (!this.selectedMarket || !this.clientSN || !this.baseAmount) {
        console.error("Missing data for base quote request");
        return;
      }

      const market = this.selectedMarket.name.replace("/", "").toLowerCase();
      const side = this.items[this.currentSelection].toLowerCase();
      const toAmount = this.baseAmount;
      const sn = this.clientSN;
      const prefunded = 0;

      const apiUrl = `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetQuoteTo?market=${market}&side=${side}&to_amount=${toAmount}&sn=${sn}&prefunded=${prefunded}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (!data.quote_token) {
          this.requested = false;
          this.$toast.error("Please, check your account balance and try again.");
          return;
        }

        this.quoteToken = data.quote_token;
        this.rate = data.rate;
        this.totalCostfrom = data.from_amount;
        this.startQuoteRequestInterval();
      } catch (error) {
        this.$toast.error("Could not generate quote.");
        console.error("Error fetching base quote:", error);
      }
    },
    async getQuoteFrom() {
      if (!this.selectedMarket || !this.clientSN || !this.quoteAmount) {
        console.error("Missing data for quote from request");
        return;
      }

      const market = this.selectedMarket.name.replace("/", "").toLowerCase();
      const side = this.items[this.currentSelection].toLowerCase();
      const fromAmount = this.quoteAmount;
      const sn = this.clientSN;
      const prefunded = 0;

      const apiUrl = `https://hodl-vm.northeurope.cloudapp.azure.com/api/GetQuotefrom?market=${market}&side=${side}&from_amount=${fromAmount}&sn=${sn}&prefunded=${prefunded}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (!data.quote_token) {
          this.$toast.error("Please, check your account balance and try again.");
          this.requested = false;
          return;
        }

        this.quoteToken = data.quote_token;
        this.rate = data.rate;
        this.totalCostto = data.to_amount;
        this.startQuoteRequestInterval();
      } catch (error) {
        this.$toast.error("Could not generate quote.");
      }
    },
    async handleAccept() {
      this.acceptButtonDisabled = true;
      try {
        const result = await this.acceptQuote();
        if (result) {
          this.showSuccess = true;
          await this.checkAndUpdateTradeBalance();
        } else {
          this.$toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        this.$toast.error("Something went wrong. Please try again.");
      } finally {
        this.acceptButtonDisabled = false;
        
      }
    },
    acceptQuote() {
      return new Promise((resolve, reject) => {
        if (!this.quoteToken) {
          console.error("Attempted to accept quote without a quote token.");
          this.snackbarMessage = "Please check balance and try again.";
          this.snackbar = true;
          this.requested = false;
          
          return reject(new Error("No quote token available."));
        }

        const primaryApiUrl = `https://hodl-vm.northeurope.cloudapp.azure.com/api/AcceptQuote`;
        const fallbackApiUrl = `https://hodl-vm.northeurope.cloudapp.azure.com/api/AcceptQuoteMain`;

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ quote_token: this.quoteToken }),
        };

        fetch(primaryApiUrl, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            this.snackbarMessage = "Attempting fallback endpoint...";
            this.snackbar = true;

            fetch(fallbackApiUrl, requestOptions)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(
                    "Network response from fallback was not ok"
                  );
                }
                return response.json();
              })
              .then((data) => {
                resolve(data);
              })
              .catch((fallbackError) => {
                this.snackbarMessage = fallbackError.message;
                this.snackbar = true;
                this.resetState();
                resolve(null);
              });
          });
      });
    },
    async checkAndUpdateTradeBalance() {
      try {
        const checkEmailResponse = await fetch(
          `https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralSelectFiltered?email=${this.email}`
        );
        const checkEmailData = await checkEmailResponse.json();

        if (checkEmailData.length > 0) {
          const userRecord = checkEmailData.find(
            (record) => record.email === this.email
          );

          if (userRecord) {
            const currentTradeAmount = Number(userRecord.tradeAmount) || 0;
            const baseAmount = Number(this.baseAmount) || 0;
            const newTradeAmount = currentTradeAmount + baseAmount;

            const tradeBalanceUpdateUrl = `https://hodl-vm.northeurope.cloudapp.azure.com/api/CompletedReferralUpdate`;
            const updateResponse = await fetch(tradeBalanceUpdateUrl, {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                email: this.email,
                TradeAmount: newTradeAmount,
              }),
            });

            if (updateResponse.ok) {
              console.log("Trade balance updated successfully.");
            } else {
              this.$toast.error("Failed to update trade balance.");
            }
          } else {
            this.$toast.error("User record not found in the fetched data.");
          }
        } else {
          this.$toast.error(
            "No person found for referral."
          );
        }
      } catch (error) {
        this.$toast.error("Error checking email and updating trade balance.");
        console.error(
          "Error checking email and updating trade balance:",
          error
        );
      }
    },
    startQuoteRequestInterval() {
      if (this.quoteRequestIntervalId) {
        clearInterval(this.quoteRequestIntervalId);
      }

      this.quoteRequestCounter = 0;
      this.quoteRequestIntervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.quoteRequestCounter++;
          if (this.baseAmount) {
            this.getQuoteBase();
          } else if (this.quoteAmount) {
            this.getQuoteFrom();
          }

          if (this.quoteRequestCounter >= 10) {
            clearInterval(this.quoteRequestIntervalId);
            this.acceptButtonDisabled = true;
          }
        } else {
          clearInterval(this.quoteRequestIntervalId);
          this.acceptButtonDisabled = true;
        }
      }, 3000);
    },
    handleQuoteRefresh() {
      this.acceptButtonDisabled = false;
      this.startQuoteRequestInterval();
      this.startCountdown();
    },
    handleDecline() {
      this.requested = false;
      this.resetState();
    },
  },
  watch: {
    requested(newValue) {
      if (newValue) {
        this.startCountdown();
      } else {
        this.resetCountdown();
      }
    },
    selectedMarket: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && this.initialized) {
          this.fetchAndFilterCurrencies();
          this.fetchAndFillMaxAmount();
        }
      },
      deep: true,
    },
    baseAmount(newVal) {
      if (newVal) {
        this.quoteAmount = "";
      }
    },
    quoteAmount(newVal) {
      if (newVal) {
        this.baseAmount = "";
      }
    },
  },
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotating-loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
